/* eslint-disable no-lone-blocks */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Grid, Stack, Container, Box, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
// import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFSelect } from '../../../components/hook-form';

import axiosInstance from '../../../utils/axios';
import { permissions } from '../Amenities';
// import { permission } from '../Amenities';

export default function AddPermission() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCheckboxes((prevSelected) => {
      const newSelected = checked
        ? [...prevSelected, String(value)]
        : prevSelected.filter((item) => item !== String(value));
      return newSelected;
    });
  };
  useEffect(() => {
    const result = state?.permissions.map((item) => String(item.id));
    setSelectedCheckboxes(result || []);
  }, []);

  const [data, setdata] = useState([]);
  const [permission, setpermission] = useState([]);
  const GetAllAdmin = () => {
    axiosInstance
      .get('/subadmin')
      .then((response) => {
        if (response.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    permissions()
      .then((e) => {
        setpermission(e?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
    GetAllAdmin();
  }, []);

  const NewProviderSchema = Yup.object().shape({
    user_id: Yup.string().required('user is required'),
  });

  const defaultValues = useMemo(
    () => ({
      user_id: state?.id || '',
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewProviderSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const Data = new FormData();
      {
        selectedCheckboxes?.forEach((Ids, index) => Data.append(`permissions[${index}]`, Ids));
      }

      Data.append('user_id', data?.user_id);
      await axiosInstance.post('/permission', Data).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message, { variant: 'success' });

          navigate('/dashboard/subadmin');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Permission" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} mt={5}>
            <Card sx={{ p: 3 }}>
              <Box>
                <Grid item xs={12}>
                  <RHFSelect
                    name={'user_id'}
                    label="Select Subadmin"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {
                      // eslint-disable-next-line arrow-body-style
                      data?.map((e) => {
                        return (
                          <MenuItem key={e.id} value={e.id}>
                            {e.first_name + ' ' + e?.last_name}
                          </MenuItem>
                        );
                      })
                    }
                  </RHFSelect>

                  {/* </RHFSelect> */}
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Permission</h2>
                <Grid item xs={12}>
                  {permission?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option?.id}
                      control={
                        <Checkbox
                          checked={selectedCheckboxes.includes(String(option?.id))}
                          onChange={handleCheckboxChange}
                          value={option?.id}
                        />
                      }
                      label={option.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                    />
                  ))}
                </Grid>
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Add Permission
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
